import React from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PostList from "../components/PostList"

const BlogIndex = ({
  data,
  pageContext: { nextPagePath, previousPagePath },
}) => {
  const posts = data.allWpPost.nodes

  if (!posts.length) {
    return (
      <p>
        No blog posts found. Add posts to your WordPress site and they'll appear
        here!
      </p>
    )
  }

  return (
    <Layout isHomePage>
      <Seo title="All posts" />
      <PostList posts={posts} />

      {previousPagePath && (
        <>
          <Link to={previousPagePath}>Previous</Link>
          <br />
        </>
      )}
      {nextPagePath && <Link to={nextPagePath}>Next</Link>}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
        tags {
          nodes {
            name
            slug
            uri
            count
            id
          }
        }
      }
    }
  }
`
